import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import Button from "../../ui/Button";
import Drawer from "../../ui/Drawer";
import QuestionCreate from "./QuestionCreate";


const QuestionsContainer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);


  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  }
  return (
    <div>
      <div className="p-2 bg-primary-50 rounded flex justify-between items-center">
        <span>Questions</span>
        <Button size="small" onClick={handleDrawerOpen} variant="primary" className="flex justify-between items-center gap-1" >Add  <FaPlus /> </Button>
      </div>
      <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} width="60%">
        <QuestionCreate closeDrawer={() => {
          setIsDrawerOpen(false);
        }} />
      </Drawer>
    </div>
  );
}

export default QuestionsContainer;