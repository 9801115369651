import React, { ChangeEvent } from "react";

interface TextAreaProps {
  /**
   * The current value of the textarea.
   */
  value: string;
  /**
   * Function called when the textarea value changes.
   */
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  /**
   * Placeholder text for the textarea.
   */
  placeholder?: string;
  /**
   * Number of rows for the textarea.
   */
  rows?: number;
  /**
   * Number of columns for the textarea.
   */
  cols?: number;
  /**
   * If true, disables the textarea.
   */
  disabled?: boolean;
  /**
   * Additional className for styling.
   */
  className?: string;
  id: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  value,
  onChange,
  placeholder = "",
  rows = 4,
  cols = 50,
  disabled = false,
  id = "",
  className = "",
}) => {
  return (
    <textarea
      id={id}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      rows={rows}
      cols={cols}
      disabled={disabled}
      className={`border rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 ${className}`}
    />
  );
};

export default TextArea;