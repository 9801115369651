import React from "react";
import { FaExclamationCircle } from "react-icons/fa";

interface DataNotFoundProps {
  message?: string;
  className?: string; // Optional for custom styles or container size
}

const DataNotFound: React.FC<DataNotFoundProps> = ({ message, className }) => {
  return (
    <div
      className={`flex flex-col items-center justify-center bg-gradient-radial from-primary-50 to-primary-200 rounded-lg p-6 ${className}`}
    >
      <FaExclamationCircle className="text-primary-500 text-5xl mb-4 animate-bounce" />
      <h1 className="text-primary-500 text-xl font-semibold">
        {message || "No data found."}
      </h1>
    </div>
  );
};

export default DataNotFound;