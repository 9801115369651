import './App.css';
import { Route, Routes } from 'react-router';
import PrivateLayout from './Layout/PrivateLayout';
import QuizzContainer from './Components/pages/quizz/QuizzContainer';
import QuestionsContainer from './Components/pages/questions/QuestionsContainer';
import QuizzList from './Pages/QuizzList';
import Error404 from './Components/pages/errors/Error404';
import { Suspense } from 'react';

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<PrivateLayout />}>
          <Route index element={<h1>Home</h1>} />
          <Route path="dashboard" element={<h1>Home</h1>} />
          <Route path="questions" element={<QuestionsContainer />} />
          <Route path="quizz/:quizzId" element={<QuizzContainer />} />
          <Route path="quizz-list" element={<QuizzList />} />
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

export default App;
