import React from "react";
import { FaBug } from "react-icons/fa";

const InternalError: React.FC = () => {
  return (
    <div className="flex h-screen items-center justify-center bg-gradient-conic from-primary-300 via-primary-400 to-primary-600">
      <div className="text-center animate-fadeInDown">
        <FaBug className="text-primary-50 text-6xl mb-4 animate-spin-slow" />
        <h1 className="text-primary-50 text-4xl font-bold">500</h1>
        <p className="text-primary-100 mt-2">
          Something went wrong on our end. Please try again later.
        </p>
        <button
          onClick={() => window.location.reload()}
          className="mt-6 px-6 py-2 text-primary-900 bg-primary-50 rounded-lg shadow hover:bg-primary-200 hover:text-primary-600 transition-all duration-300"
        >
          Reload Page
        </button>
      </div>
    </div>
  );
};

export default InternalError;