import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { FaTimes, FaTimesCircle } from "react-icons/fa";

type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  position?: "left" | "right";
  bgColor?: string;
  width?: string;
};

const Drawer: React.FC<DrawerProps> = ({ isOpen, onClose, children, position = "right", bgColor = "bg-white", width = "w-64" }) => {

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") onClose();
    };
    if (isOpen) document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);


  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex">
      {/* Overlay */}
      <div
        className="fixed inset-1 bg-black bg-opacity-50"
        onClick={onClose}
      ></div>

      {/* Drawer Content */}
      <div
        className={`fixed -top-0 bottom-0 shadow-lg animate-ease-out  transform ${position === "right" ? "right-0 animate-fade-left" : "left-0 animate-fade-right "
          } transition-transform duration-300 ${bgColor} ${width}`}
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-1 right-1 text-gray-600 hover:text-black"
        >
          <FaTimesCircle />
        </button>
        {/* Content */}
        <div className="p-4">{children}</div>
      </div>
    </div>,
    document.body
  );
};

export default Drawer;