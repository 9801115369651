import { FC } from "react";

const Question: FC<
  {
    question: string;
  }
> = ({
  question
}) => {
    return (
      <div className="p-2 bg-primary-100 rounded-md text-lg">
        {question}
      </div>
    );
  }

export default Question;