import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { miles_quizz_db } from "../../../service/SupabaseClientInstance";
import LoadingComponent from "../../common/Loading";
import DataNotFound from "../../common/DataNotFound";
import QuizStartScreen from "./QuizzStartButton";
import QuizzQuestionDisplay from "./QuizzQuestionDisplay";
import QuizzFinesh from "./QuizzFinesh";

const QuizzContainer = () => {

  const { quizzId } = useParams<{ quizzId: string }>();
  const [quizzData, setQuizzData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const getQuizzData = async () => {
    setLoading(true)
    try {
      const { data, error } = await miles_quizz_db.from("quizz_list").select("*").eq("id", quizzId)
      if (!error) {
        setQuizzData(data[0])
      } else {
        console.log("Error fetching data", error)
      }
    } catch (error) {
      console.log("Error fetching data", error)
    } finally {
      setLoading(false)
    }
  };

  const quizzeStarted = () => {
    setQuizzData((prevData: any) => {
      return {
        ...prevData,
        isStarted: true
      }
    })
  }

  useEffect(() => {
    getQuizzData()
  }, [quizzId])

  useEffect(() => {
    const subscription = miles_quizz_db
      .channel(`quizz_list:id=eq.${quizzId}`)
      .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'quizz_list' }, (payload) => {
        setQuizzData(payload?.new)
      })
      .subscribe((status, error) => {
        console.log('Subscription status:', status);
        if (error) {
          console.error('Subscription error:', error);
        }
      });

    return () => {
      miles_quizz_db.removeChannel(subscription);
    };
  }, [])


  return (
    <div className="flex justify-start gap-2  h-full">
      {loading ? <LoadingComponent className="w-full h-full" /> :
        <>
          {quizzData?.id ?
            <>
              {quizzData?.quizz_start_status === 1 ?
                <>
                  {quizzData?.isStarted ? <QuizzQuestionDisplay quizzData={quizzData} /> :
                    <QuizStartScreen quizzData={quizzData} quizzStarted={quizzeStarted} />}
                </> : <QuizzFinesh quizzData={quizzData} />}
            </> :
            <DataNotFound message="Oops! We couldn't find any quizzes." className="w-full h-full" />}
        </>
      }
    </div>
  )
}

export default QuizzContainer;