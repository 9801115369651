import { FC } from "react";

const Option: FC<{
  option: string;
  tag: string;
  isCorrect: boolean;
}> = ({ option, tag,isCorrect}) => {
  return (
    <div className={`p-2 rounded-md text-[18px] text-primary-700 ${isCorrect ? "bg-green-300 hover:bg-green-400 " : " bg-primary-100 hover:bg-primary-200 "}`}>
      <span className={`inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white  rounded-full ${isCorrect ? "bg-green-800" : "bg-primary-800"}`}>{tag}</span>  {option}
    </div>
  );
};

export default Option;