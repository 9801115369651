// CommonButton.tsx
import React from "react";

interface CommonButtonProps {
  /**
   * Text or content to display inside the button.
   */
  children: React.ReactNode;
  /**
   * Determines the button variant (e.g., primary, secondary).
   */
  variant?: "primary" | "secondary" | "danger" | "success";
  /**
   * Determines the button size.
   */
  size?: "small" | "medium" | "large";
  /**
   * Callback function triggered on button click.
   */
  onClick?: () => void;
  /**
   * If true, disables the button.
   */
  disabled?: boolean;
  /**
   * Additional className for styling.
   */
  className?: string;
  /**
   * Type of the button (default is "button").
   */
  type?: "button" | "submit" | "reset";
}

const Button: React.FC<CommonButtonProps> = ({
  children,
  variant = "primary",
  size = "medium",
  onClick,
  disabled = false,
  className = "",
  type = "button",
}) => {
  const baseClass = "rounded font-semibold focus:outline-none flex justify-between items-center gap-1";
  const variantClass = {
    primary: "bg-primary-400 text-white hover:bg-primary-500",
    secondary: "bg-gray-500 text-white hover:bg-gray-600",
    danger: "bg-red-500 text-white hover:bg-red-600",
    success: "bg-green-500 text-white hover:bg-green-600",
  }[variant];

  const sizeClass = {
    small: "py-1 px-3 text-sm",
    medium: "py-2 px-4 text-base",
    large: "py-3 px-6 text-[18px] line-height-[21px]",
  }[size];

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`${baseClass} ${variantClass} ${sizeClass} ${disabled ? "opacity-50 cursor-not-allowed" : ""
        } ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;