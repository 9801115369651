import { FC } from 'react';
import { Outlet } from 'react-router';
import UserProvider from '../Providers/UserProvider';
import Header from '../Components/common/Header';

const PrivateLayout: FC = () => {
  return (
    <UserProvider>
      <div className='h-screen  from-primary-50 overflow-hidden'>
        <Header />
        <main className='p-2 md:p-3 h-[calc(100%-100px)] overflow-auto bg-opacity-20 w-[99vw] rounded m-auto'>
          <Outlet />
        </main>
      </div>
    </UserProvider>
  );
};

export default PrivateLayout;
