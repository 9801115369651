import { FC, useState } from "react";
import Button from "../../ui/Button";
import TextArea from "../../ui/TextArea";
import { FaPlus, FaTrash } from "react-icons/fa";
import RadioButton from "../../ui/RadioButton";
import { errorAlert, successAlert } from "../../../lib/alert";

interface IFormData {
  question: string;
  options: string[];
  correctOption: string
}

const QuestionCreate: FC<{
  closeDrawer: () => void;
}> = ({
  closeDrawer
}) => {

    const formInitialData: IFormData = {
      question: "",
      options: [""],
      correctOption: ""
    }

    const [formData, setFormData] = useState<IFormData>(formInitialData);
    const [formSubmiting, setFormSubmiting] = useState<boolean>(false);

    const checkAllOptionsAreFilled = (): boolean => {
      return formData.options.every(option => option.trim().length > 0);
    }

    const handleSubmit = async () => {
      try {
        if (formData.question.trim().length === 0) {
          throw new Error("Question cannot be empty");
        } else if (!checkAllOptionsAreFilled()) {
          throw new Error("Options cannot be empty");
        } else if (formData.correctOption.trim().length === 0) {
          throw new Error("Correct Answer is required");
        } else {
          const payload = {
            question: formData.question,
            options: formData.options,
            correctOption: Number(formData.correctOption)
          }
          successAlert("Question created successfully");
          console.log(formData);
          closeDrawer();
        }
      } catch (error: any) {
        errorAlert(error?.message ?? "An error occurred");
      } finally {
        setFormSubmiting(false);
      }
    }


    return (
      <div className="p-2 overflow-auto h-full">
        <div>
          <label htmlFor="question" className="text-primary-700">Enter question</label>
          <TextArea id="question" onChange={(e) => {
            setFormData({
              ...formData,
              question: e.target.value
            })
          }} value={
            formData.question
          } placeholder="Enter question" className="w-full p-2 border border-primary-300 rounded-md" />
        </div>
        <div className="flex justify-end">
          <Button
            onClick={() => {
              setFormData({
                ...formData,
                options: [...formData.options, ""]
              })
            }}
            size="small" variant="secondary" className="mt-2">Add Option <FaPlus /> </Button>
        </div>
        {
          formData.options.map((option, index) => (
            <div className="bg-primary-50 rounded my-1 p-1 relative">
              <label htmlFor={`option-${index}`}>Option {String.fromCharCode(65 + index)}</label>
              <TextArea
                id={`option-${index + 1}`}
                rows={2}
                onChange={(e) => {
                  const newOptions = formData.options.map((opt, i) => {
                    if (i === index) {
                      return e.target.value;
                    }
                    return opt;
                  });
                  setFormData({
                    ...formData,
                    options: newOptions
                  })
                }} value={option} placeholder={`Enter option ${String.fromCharCode(65 + index)}`} className="w-full p-2 border border-primary-300 rounded-md mt-2
            " />
              {formData.options.length > 1 &&
                <Button onClick={() => {
                  const newOptions = formData.options.filter((opt, i) => {
                    return i !== index;
                  });

                  setFormData({
                    ...formData,
                    options: newOptions,
                    correctOption: formData.correctOption === index.toString() ? "" : formData.correctOption
                  })
                }} className="absolute top-0 right-0 bg-transparent text-primary-400 hover:text-primary-600 hover:bg-transparent"><FaTrash /></Button>
              }
            </div>
          ))
        }

        <div className="bg-primary-100 p-2 rounded">
          <h4>Correct Answer</h4>
          <div className="flex gap-2">
            {
              formData.options.map((option, index) => <RadioButton key={"correct-ans-" + index} label={String.fromCharCode(65 + index)} value={index.toString()} name="correct-ans" onChange={
                (e) => {
                  setFormData({
                    ...formData,
                    correctOption: e
                  })
                }
              }
                checked={formData.correctOption === index.toString()}
              />)
            }
          </div>
        </div>
        <div className="flex justify-center">
          <Button onClick={handleSubmit} size="large" variant="primary" className="mt-2 text-center text-white" disabled={formSubmiting}>{
            formSubmiting ? "Submiting..." : "Submit"
          }</Button>
        </div>
      </div>
    );
  }

export default QuestionCreate;