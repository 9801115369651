import React from "react";
import { miles_quizz_db } from "../../../service/SupabaseClientInstance";
import { errorAlert } from "../../../lib/alert";

const QuizStartScreen: React.FC<{
  quizzData: any;
  quizzStarted: () => void
}> = ({
  quizzData,
  quizzStarted
}) => {
    const [startQuizLoading, setStartQuizLoading] = React.useState(false);
    const todayDate = new Date().toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });


    const startQuiz = async () => {
      setStartQuizLoading(true);
      try {
        const { data, error } = await miles_quizz_db.from("quizz_list").update({ isStarted: true, quizz_current_question: quizzData?.questions[0] }).eq("id", quizzData?.id);
        if (!error) {
          quizzStarted();
        } else {

          errorAlert("Error while starting quiz");
        }
      } catch (error) {
        errorAlert("Error while starting quiz");
        console.error("Error starting quiz", error);
      } finally {
        setStartQuizLoading(false);
      }
    }



    return (
      <div className="h-full w-full bg-gradient-radial from-primary-50 to-primary-200 flex flex-col items-center justify-center relative rounded-lg">
        {/* Welcome Note and Today's Date */}
        <div className="absolute top-4 right-4 text-right">
          <p className="text-primary-500 text-xl font-semibold">Welcome to the {quizzData?.exam_title}</p>
          <p className="text-primary-600">{todayDate}</p>
        </div>

        {/* Start Button */}
        <button
          disabled={startQuizLoading}
          onClick={startQuiz}
          className="px-8 py-4 bg-primary-200 text-primary-700 text-2xl font-bold rounded-lg shadow-lg hover:bg-primary-300 hover:text-primary-600 transition-all duration-300"
        >
          {startQuizLoading ? "Starting..." : "Start Quiz"}
        </button>
      </div>
    );
  };

export default QuizStartScreen;