import { FC, useEffect, useState } from "react";
import Question from "./Question";
import Option from "./Option";
import { miles_quizz_db } from "../../../service/SupabaseClientInstance";
import { errorAlert } from "../../../lib/alert";
import LoadingComponent from "../../common/Loading";
import DataNotFound from "../../common/DataNotFound";
import Button from "../../ui/Button";
import { FaForward, FaLightbulb } from "react-icons/fa";

const QuizzQuestionDisplay: FC<{
  quizzData: any;
}> = ({
  quizzData
}) => {
    const Alphabates = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"];
    const [loading, setLoading] = useState<boolean>(false);
    const [questionData, setQuestionData] = useState<any>(null);
    const [questionRevealed, setQuestionRevealed] = useState<boolean>(false);
    const [revelButton, setRevelButton] = useState<boolean>(false);
    const [nextQuestionLoading, setNextQuestionLoading] = useState<boolean>(false);
    const [fineshQuizzLoading, setFineshQuizzLoading] = useState<boolean>(false);
    const [buttonsLock, setButtonsLock] = useState<boolean>(false);

    const getCurrentQuestionData = async () => {
      setLoading(true)
      try {
        let { data: quizz_questions_data, error } = await miles_quizz_db
          .from('quizz_questions_data')
          .select('question,answers,correctAns')
          .eq("question_status", true)
          .eq("id", quizzData?.quizz_current_question);
        if (!error) {
          if (quizz_questions_data && quizz_questions_data.length > 0) {
            setQuestionData(quizz_questions_data[0]);
            checkQuestionAleadyThereOrNot(quizz_questions_data[0]?.correctAns);
          } else {
            setQuestionData(null);
          }
        } else {
          errorAlert("Error fetching data");
        }
      } catch (error) {
        console.log("Error fetching data", error)
      } finally {
        setLoading(false)
      }
    }

    // const fetchAnswerCounts = async () => {
    //   try {
    //     const { data, error } = await miles_quizz_db
    //       .from('user_answers_data')
    //       .select('correct_ans, count:correct_ans')
    //       .eq('quizz_id', quizzData?.id)
    //       .eq('question_id', quizzData?.quizz_current_question)
    //       .group('correct_ans')  // Group by correct_ans field
    //       .order('correct_ans', { ascending: true });

    //     if (error) {
    //       throw error;
    //     }

    //     // Map the results into a format we need
    //    console.log(data)
    //   } catch (error) {
    //     console.error('Error fetching answer counts:', error);
    //   }
    // };

    const nextQuestionDataInject = async (ans: number) => {
      setQuestionRevealed(false)
      try {
        const { error } = await miles_quizz_db.from("quizz_questions_live_data").insert([{ quizz_id: quizzData?.id, question_id: quizzData?.quizz_current_question, correct_ans: ans }])

      } catch (e) {
        console.error("Error while fetching next question", e)
      }
    }

    const revealAnswer = async () => {
      setRevelButton(true);
      try {
        const { data, error } = await miles_quizz_db.from("quizz_questions_live_data").update({ isRevealed: true, isCompleted: true }).eq("quizz_id", quizzData?.id).eq("question_id", quizzData?.quizz_current_question);
        if (!error) {
          setQuestionRevealed(true)
        }
      } catch (error) {
        console.log("Error fetching data", error)
      } finally {
        setRevelButton(false);
      }
    }

    const checkQuestionAleadyThereOrNot = async (ans: number) => {
      try {
        const { data, error } = await miles_quizz_db.from("quizz_questions_live_data").select("*").eq("quizz_id", quizzData?.id).eq("question_id", quizzData?.quizz_current_question)
        if (!error) {
          if (data?.length > 0) {
            setQuestionRevealed(data[0]?.isRevealed)
          } else {
            nextQuestionDataInject(ans);
          }
        }
      } catch (error) {
        console.log("Error fetching data", error)
      }
    }

    const buttonsLockHandler = () => {
      setButtonsLock(true);
      setTimeout(() => {
        setButtonsLock(false);
      }, 3000)
    }

    const getAnsweredQuestionCount = async (quizz_id: string, question_id: number) => {
      const { data, error } = await miles_quizz_db
        .rpc("get_ans_counts", {
          quizz_id: quizz_id,
          question_id: question_id
        });

      if (!error) {
        console.log(data)
      }
    }

    const updateNextQuestion = async () => {
      setNextQuestionLoading(true);
      try {
        const { data, error } = await miles_quizz_db.from("quizz_list").update({ quizz_current_question: quizzData?.questions[quizzData?.questions.indexOf(quizzData?.quizz_current_question) + 1] }).eq("id", quizzData?.id);
        if (!error) {
          getCurrentQuestionData();
          setQuestionRevealed(false);
        }
      } catch (error) {
        console.log("Error fetching data", error)
      } finally {
        setNextQuestionLoading(false);
      }
    }

    const fineshQuizz = async () => {
      setFineshQuizzLoading(true);
      try {
        const { data, error } = await miles_quizz_db.from("quizz_list").update({ quizz_start_status: 2, quizz_live_status: false }).eq("id", quizzData?.id);
        if (!error) {
          getCurrentQuestionData();
        }
      } catch (error) {
        console.log("Error fetching data", error)
      } finally {
        setFineshQuizzLoading(false);
      }
    }

    useEffect(() => {
      if (quizzData?.quizz_current_question !== null && quizzData?.quizz_start_status === 1) {
        getCurrentQuestionData();
        setQuestionRevealed(false);
        buttonsLockHandler();
        // getAnsweredQuestionCount(quizzData?.id,quizzData?.quizz_current_question);
      }
    }, [quizzData?.quizz_current_question])

    return (
      <>
        {loading ? <LoadingComponent className="w-full h-full" /> :
          <>
            {questionData ? <>
              <div className="flex flex-col justify-between w-full h-full gap-2">
                <div className="flex flex-col w-full gap-2">
                  <Question question={questionData.question} />
                  <div className="grid sm:grid-cols-2 gap-2">
                    {
                      questionData.answers.map((option: string, index: number) => (
                        <Option key={"option-" + index}
                          isCorrect={questionRevealed && questionData?.correctAns === index.toString()}
                          option={option} tag={Alphabates[index]} />
                      ))
                    }
                  </div>
                </div>

                <div className="flex justify-center gap-3 bg-primary-100 p-2 rounded-md">
                  {!questionRevealed &&
                    <Button onClick={revealAnswer} disabled={revelButton || buttonsLock} variant="primary">
                      {revelButton ? "Loading...." : <>Reveal Answer <FaLightbulb /> </>}
                    </Button>}
                  {(quizzData?.quizz_current_question !== quizzData?.questions[quizzData?.questions.length - 1] && questionRevealed) && <Button
                    disabled={nextQuestionLoading || buttonsLock}
                    onClick={updateNextQuestion}
                    variant="secondary">{
                      nextQuestionLoading ? "Loading...." : <>Next Question <FaForward /></>
                    }</Button>}
                  {questionRevealed &&
                    quizzData?.quizz_current_question === quizzData?.questions[quizzData?.questions.length - 1] && <Button
                      onClick={fineshQuizz}
                      variant="danger"
                      disabled={fineshQuizzLoading || buttonsLock}
                    >
                      Finish Quiz
                    </Button>
                  }
                </div>
              </div>
            </> : <DataNotFound className="w-full h-full" message="Question Not Found" />
            }
          </>
        }
      </>
    )
  }

export default QuizzQuestionDisplay;