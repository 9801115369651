import React, { useRef, useState } from "react";
import { FaPlay, FaPause } from "react-icons/fa";

const MusicPlayer: React.FC = () => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <>
      <audio
        ref={audioRef}
        src="/assets/kbc-awesome-5410.mp3" // Replace with your audio file path
        loop
      ></audio>
      <button
        onClick={togglePlay}
        className="p-4 bg-primary-50 text-primary-900 rounded-full shadow-lg hover:bg-primary-200 hover:scale-105 transition-all duration-300"
      >
        {isPlaying ? <FaPause /> : <FaPlay />}
      </button>
    </>

  );
};

export default MusicPlayer;