import { FaExclamationTriangle } from "react-icons/fa";
import { useNavigate } from "react-router";

const Error404 = () => {
  const navigate = useNavigate();
  return (
    <div className="flex h-screen items-center justify-center bg-gradient-radial from-primary-300 to-primary-900 rounded-md bg-opacity-50">
      <div className="text-center animate-fadeIn flex flex-col justify-center items-center">
        <FaExclamationTriangle className="text-primary-50 text-6xl mb-4 animate-pulse" />
        <h1 className="text-primary-50 text-4xl font-bold">404</h1>
        <p className="text-primary-100 mt-2">
          Oops! The page you're looking for doesn't exist.
        </p>
        <button
          onClick={() => navigate("/dashboard")}
          className="mt-6 px-6 py-2 text-primary-900 bg-primary-50 rounded-lg shadow hover:bg-primary-200 hover:text-primary-600 transition-all duration-300"
        >
          Go DashBoard
        </button>
      </div>
    </div>
  );
}

export default Error404;