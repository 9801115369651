import { FC } from "react";
import MusicPlayer from "./MusicPlayer";

const Header: FC = () => {
  return (
    <header className="w-[99vw] h-[60px] p-2 md:p-3 flex items-center justify-between bg-primary-400 bg-opacity-50 rounded-lg m-auto my-2">
      <img src="/assets/logo.webp" alt="Logo" className="w-24 " />
      <MusicPlayer />
    </header>
  );
}

export default Header;