import TitleComponent from "../../common/TitleComponent";
import QuizzListTableData from "./TableData";

const QuizzListContainer = () => {
  return (
    <div>
      <TitleComponent title="Quiz List" />
      <QuizzListTableData />
    </div>
  );
}

export default QuizzListContainer;