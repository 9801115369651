import React from "react";

interface RadioButtonProps {
  name: string;
  value: string;
  label: string;
  checked: boolean;
  onChange: (value: string) => void;
  className?: string; // Additional TailwindCSS classes
}

const RadioButton: React.FC<RadioButtonProps> = ({
  name,
  value,
  label,
  checked,
  onChange,
  className = "",
}) => {
  return (
    <label className={`flex items-center space-x-2 ${className}`}>
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={() => onChange(value)}
        className="form-radio text-primary-600 h-5 w-5"
      />
      <span className="text-gray-700">{label}</span>
    </label>
  );
};

export default RadioButton;