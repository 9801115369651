import { FC } from "react"
import ResultComponent from "./ResultComponent";
import Button from "../../ui/Button";
import { useNavigate } from "react-router";

const QuizzFinesh: FC<{
  quizzData: any
}> = ({
  quizzData
}) => {
    const navigate = useNavigate();
    return (
      <div className="h-full w-full bg-gradient-radial from-primary-50 to-primary-200 flex flex-col items-center justify-center relative rounded-lg gap-2">
        <h1 className="text-primary-500 text-5xl">Quiz Finished</h1>
        {/* <ResultComponent quizzData={quizzData} /> */}
        <Button variant="primary" onClick={() => {
          navigate('/quizz-list')
        }}>Back To Quiz List</Button>
      </div>
    )
  }

export default QuizzFinesh;