import React from "react";

interface LoadingComponentProps {
  message?: string;
  className?: string; // Optional for custom styles or container size
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({ message, className }) => {
  return (
    <div
      className={`flex flex-col items-center justify-center bg-gradient-radial from-primary-50 to-primary-200 rounded-lg p-6 ${className}`}
    >
      <div className="w-12 h-12 border-4 border-primary-500 border-t-transparent rounded-full animate-spin"></div>
      <p className="text-primary-500 text-xl mt-4">{message || "Loading..."}</p>
    </div>
  );
};

export default LoadingComponent;