import { useEffect, useState } from "react";
import Button from "../../ui/Button";
import { miles_quizz_db } from "../../../service/SupabaseClientInstance";
import { errorAlert, successAlert } from "../../../lib/alert";
import LoadingCompoenet from "../../common/Loading";
import DataNotFound from "../../common/DataNotFound";
import { useNavigate } from "react-router";

const QuizzListTableData = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [statusUpdateing, setStatusUpdateing] = useState(false);
  const [rows, setRows] = useState<any[]>([]);

  const getQuizzList = async () => {
    setLoading(true)
    try {
      const { data, error } = await miles_quizz_db.from("quizz_list").select("*").order("order", { ascending: true })
      if (!error) {
        setRows(data)
      } else {
        console.log("Error fetching data", error)
      }
    } catch (error) {
      errorAlert("Error fetching data")
    } finally {
      setLoading(false)
    }
  }

  const toggleQuizzStatus = async (quizzId: string, status: boolean) => {
    setStatusUpdateing(true);
    try {
      const { data, error } = await miles_quizz_db.from("quizz_list").update({ exam_status: !status }).eq("id", quizzId)
      if (!error) {
        successAlert("Quiz status updated successfully");
        setRows((prevRows) => {
          return prevRows.map((row) =>
            row.id === quizzId ? { ...row, exam_status: !status } : row
          )
        })
      } else {
        console.log("Error updating quiz status", error)
      }
    } catch (error) {
      errorAlert("Error updating quiz status")
    } finally {
      setStatusUpdateing(false)
    }
  }

  const startQuizz = async (quizzId: string) => {
    setStatusUpdateing(true);
    try {
      const { data, error } = await miles_quizz_db.from("quizz_list").update({ quizz_start_status: 1, quizz_live_status: true }).eq("id", quizzId)
      if (!error) {
        successAlert("Quiz started successfully");
        navigate(`/quizz/${quizzId}`)
      } else {
        console.log("Error updating quiz status", error)
      }
    } catch (error) {
      errorAlert("Error updating quiz status")
    } finally {
      setStatusUpdateing(false)
    }
  };

  const continueAndResultQuizz = async (quizzId: string) => {
    navigate(`/quizz/${quizzId}`)
  };

  useEffect(() => {
    getQuizzList()
  }, [])

  return (
    <div className="overflow-x-auto">
      {loading ? <LoadingCompoenet message="Fetching your data, please wait..."
        className="w-full h-full" /> :
        <>
          {rows?.length > 0 ?
            <table className="min-w-full bg-primary-50 shadow-lg rounded-lg">
              <thead className="bg-primary-400 text-primary-50">
                <tr>
                  <th className="px-4 py-2 text-sm font-semibold text-center">S.No</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold">Title</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold">Poll Date</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold">
                    Total Questions
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-semibold">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-primary-100 text-primary-900">
                {rows.map((row, index) => (
                  <tr
                    key={row.id}
                    className={`${index % 2 === 0 ? "bg-primary-100" : "bg-primary-50"
                      } last:rounded-b-lg`}
                  >
                    <td className="px-4 py-2 text-sm text-center">{index + 1}</td>
                    <td className="px-4 py-2 text-sm">{row?.exam_title}</td>
                    <td className="px-4 py-2 text-sm">{row?.start_date}</td>
                    <td className="px-4 py-2 text-sm">{row?.questions?.length}</td>
                    <td
                      className={`px-4 py-2 flex gap-2 ${index === rows?.length - 1 ? "rounded-br-lg" : ""
                        }`}
                    >
                      {row.exam_status && row.quizz_start_status === 0 &&
                        <Button onClick={() => startQuizz(row.id)} disabled={statusUpdateing} variant="primary"  >
                          Start
                        </Button>
                      }
                      {row.exam_status && row.quizz_start_status === 1 &&
                        <Button onClick={() => continueAndResultQuizz(row.id)} disabled={statusUpdateing} variant="secondary"  >
                          Continue
                        </Button>
                      }
                      {row.exam_status && row.quizz_start_status === 2 &&
                        <Button onClick={() => continueAndResultQuizz(row.id)} disabled={statusUpdateing} variant="success"  >
                          Stats
                        </Button>
                      }
                      {/* <Button
                        disabled={statusUpdateing}
                        variant={row.exam_status ? "success" : "danger"}
                        onClick={() => toggleQuizzStatus(row.id, row.exam_status)}
                      >
                        {row.exam_status ? "Active" : "Inactive"}
                      </Button> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> : <DataNotFound message="Oops! We couldn't find any quizzes."
              className="w-full h-full" />}
        </>
      }
    </div>
  )
}

export default QuizzListTableData;