import React from "react";

interface ButtonProps {
  label: string;
  onClick: () => void;
  variant?: "primary" | "secondary";
}

interface TitleComponentProps {
  title: string;
  buttons?: ButtonProps[];
}

const TitleComponent: React.FC<TitleComponentProps> = ({ title, buttons }) => {
  return (
    <div className="flex items-center justify-between py-4 px-6 bg-primary-50 rounded-t-lg shadow">
      {/* Title */}
      <h2 className="text-xl font-semibold text-primary-900">{title}</h2>

      {/* Buttons */}
      {buttons && buttons.length > 0 && (
        <div className="flex gap-4">
          {buttons.map((button, index) => (
            <button
              key={index}
              onClick={button.onClick}
              className={`px-4 py-2 rounded shadow transition ${button.variant === "secondary"
                  ? "bg-primary-50 text-primary-900 hover:bg-primary-200"
                  : "bg-primary-300 text-primary-50 hover:bg-primary-400"
                }`}
            >
              {button.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default TitleComponent;